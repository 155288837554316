import React from 'react'
import 'styled-components/macro'
import styled from 'styled-components'
import { HtmlDiv } from '../../../../../utils/types'
import Chip from '../../../../Core/Content/Chip/Chip'

const BlogCategoryFilter = ({
  categories,
  selectedCategories,
  onUpdate,
  className,
  ...rest
}: BlogCategoryFilterProps) => {
  const handleSelect = (category: string) => {
    if (selectedCategories.includes(category)) {
      onUpdate(selectedCategories.filter((c) => c !== category))
    } else {
      onUpdate([...selectedCategories, category])
    }
  }

  return (
    <div {...rest} className={`flex flex-wrap items-center ${className}`}>
      <div className="mr-4">Filter: </div>
      {categories.map((category) => (
        <div key={category} className="mr-2">
          <Chip
            className={`cursor-pointer my-1 ${
              !selectedCategories.includes(category)
                ? 'bg-accent text-accent'
                : 'bg-primary text-light'
            }`}
            onClick={() => handleSelect(category)}
          >
            {category}
          </Chip>
        </div>
      ))}
    </div>
  )
}

export interface BlogCategoryFilterProps extends HtmlDiv {
  categories: string[]
  selectedCategories: string[]
  onUpdate: (newCategories: string[]) => void
}

export default styled(BlogCategoryFilter)`
  .unselected {
    background: ${(props) => props.theme.background.colors.accent};
  }
`
