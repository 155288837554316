import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { HtmlDiv } from '../../../../../utils/types'

const BlogAvatarImage = ({ src, className, ...rest }: BlogAvatarImageProps) => {
  return (
    <div className={`rounded-full overflow-hidden ${className}`} {...rest}>
      <img src={src} />
    </div>
  )
}

export interface BlogAvatarImageProps extends HtmlDiv {
  src: string
}

export default styled(observer(BlogAvatarImage))`
  width: 2rem;
  height: 2rem;
`
