import { observer } from 'mobx-react-lite'
import React from 'react'
import styled from 'styled-components'
import { HtmlDiv } from '../../../../utils/types'
import Icon from '../Icon/Icon'

const Chip = ({
  children,
  dismissible = false,
  onDismiss = () => {},
  className,
  ...rest
}: ChipProps) => {
  return (
    <div
      className={`inline-block rounded-xl px-2 text-sm ${className}`}
      {...rest}
    >
      <div className="flex justify-between items-center w-full h-full">
        <div>{children}</div>
        {dismissible && (
          <Icon
            name="close"
            className="text-sm ml-1 cursor-pointer"
            onClick={onDismiss}
          />
        )}
      </div>
    </div>
  )
}

export interface ChipProps extends HtmlDiv {
  dismissible?: boolean
  onDismiss?: () => void
}

export default styled(observer(Chip))``
