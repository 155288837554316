import React from 'react'
import styled from 'styled-components'
import Blog from '../../../../../models/Blog'
import { HtmlDiv } from '../../../../../utils/types'
import Chip from '../../../../Core/Content/Chip/Chip'
import Link from '../../../../Core/Navigation/Link/Link'
import BlogAvatarImage from '../AvatarImage/BlogAvatarImage'

const BlogListItem = ({ blog, className, ...rest }: BlogListItemProps) => {
  return (
    <div
      className={`w-full flex justify-between items-center ${className}`}
      {...rest}
    >
      <div>
        <div className="text-2xl font-bold">
          <Link to={`/blog/${blog.slug}`}>{blog.title}</Link>
        </div>
        <div className="flex items-center">
          <BlogAvatarImage
            src="https://avatars.githubusercontent.com/u/13106307?v=4"
            className="blog-avatar-image mr-2"
          />
          <div className="text-accent font-bold">
            {blog.releaseDate.monthShort} {blog.releaseDate.day},{' '}
            {blog.releaseDate.year}
          </div>
        </div>
      </div>
      <div className="flex">
        {blog.categories.map((category) => (
          <Chip className="ml-1 bg-primary text-light" key={category}>
            {category}
          </Chip>
        ))}
      </div>
    </div>
  )
}

export interface BlogListItemProps extends HtmlDiv {
  blog: Blog
}

export default styled(BlogListItem)`
  .blog-avatar-image {
    width: 1.5rem;
    height: 1.5rem;
  }
`
