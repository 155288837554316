import Article, { TableOfContents } from './Article'
import { LoremIpsum } from 'lorem-ipsum'
import { randomNumber } from '../utils/helpers/helpers'
import { DateTime } from 'luxon'

class Blog {
  title: string
  description: string
  categories: Array<string>
  slug: string
  article: Article
  releaseDate: DateTime
  relatedBlogs: Array<Blog>

  constructor(
    title = '',
    description = '',
    categories = [] as Array<string>,
    slug = '',
    article = new Article(),
    releaseDate = DateTime.now(),
    relatedBlogs = [] as Array<Blog>
  ) {
    this.title = title
    this.description = description
    this.categories = categories
    this.slug = slug
    this.article = article
    this.releaseDate = releaseDate
    this.relatedBlogs = relatedBlogs
  }

  static fromBlogPageQuery = (b: BlogPageQuery): Blog =>
    new Blog(
      b.title,
      b.description,
      b.categories,
      b.slug,
      b.body
        ? new Article(
            b.body.body,
            b.body.timeToRead,
            b.body.wordCount.words,
            b.body.tableOfContents
          )
        : new Article(),
      DateTime.fromISO(b.releaseDate),
      b.relatedBlogs
        ? b.relatedBlogs.map((rb) => Blog.fromBlogPageQuery(rb))
        : []
    )

  static withFakeData = (includeRelatedBlogs = true): Blog => {
    const lorem = new LoremIpsum()

    return new Blog(
      lorem.generateWords(randomNumber(1, 5)),
      lorem.generateSentences(randomNumber(1, 2)),
      lorem.generateWords(randomNumber(1, 5)).split(' '),
      'recursion-in-react',
      Article.withFakeData(),
      DateTime.now(),
      includeRelatedBlogs
        ? [
            Blog.withFakeData(false),
            Blog.withFakeData(false),
            Blog.withFakeData(false),
            Blog.withFakeData(false),
            Blog.withFakeData(false),
          ]
        : []
    )
  }
}

export default Blog

export interface BlogPageQuery {
  title: string
  slug: string
  description: string
  categories: Array<string>
  body: {
    body: string
    tableOfContents: TableOfContents
    timeToRead: number
    wordCount: {
      words: number
    }
  }
  releaseDate: string
  relatedBlogs: Array<BlogPageQuery>
}

export interface AllBlogPageQuery {
  allBlog: {
    edges: Array<{
      node: BlogPageQuery
    }>
  }
}

export const BlogNode = `
  type Blog implements Node {
    title: String
    description: String
    categories: [String]
    slug: String
    body: Mdx
    releaseDate: String!
    relatedBlogs: [Blog]
  }
`
