import React, { useState } from 'react'
import styled from 'styled-components'
import _ from 'lodash'
import BlogCategoryFilter from './CategoryFilter/BlogCategoryFilter'
import BlogListItem from './ListItem/BlogListItem'
import { HtmlDiv } from '../../../../utils/types'
import Blog from '../../../../models/Blog'
import Layout from '../../Layout/Layout'
import Section from '../../../Core/Layout/Section/Section'

const BlogListPage = ({ blogs, className, ...rest }: BlogListPageProps) => {
  const allCategories = Array.from(
    new Set(_.flattenDeep(blogs.map((b) => b.categories)))
  )
  const [selectedCategories, setSelectedCategories] = useState(allCategories)

  return (
    <Layout {...rest} className={`${className}`}>
      <Section className={`flex flex-col justify-center`}>
        <BlogCategoryFilter
          categories={allCategories}
          selectedCategories={selectedCategories}
          onUpdate={setSelectedCategories}
          className="mt-10"
        />
        <br />
        {blogs
          .filter(
            (b) => _.intersection(b.categories, selectedCategories).length > 0
          )
          .map((blog) => (
            <div className="mb-4" key={blog.slug}>
              <BlogListItem blog={blog} />
            </div>
          ))}
        <br />
      </Section>
    </Layout>
  )
}

export interface BlogListPageProps extends HtmlDiv {
  blogs: Blog[]
}

export default styled(BlogListPage)``
