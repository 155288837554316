import { graphql } from 'gatsby'
import React from 'react'
import BlogListPage from '../components/MarketingSite/Pages/BlogList/BlogListPage'
import Blog, { AllBlogPageQuery } from '../models/Blog'

const Index = ({ data }: IndexPageProps) => {
  const blogs = data.allBlog.edges.map((edge) =>
    Blog.fromBlogPageQuery(edge.node)
  )
  return <BlogListPage blogs={blogs} />
}

interface IndexPageProps {
  data: AllBlogPageQuery
}

export default Index

export const query = graphql`
  query IndexPageQuery {
    allBlog {
      edges {
        node {
          title
          slug
          description
          categories
          body {
            body
            tableOfContents
            wordCount {
              words
            }
          }
          releaseDate
          relatedBlogs {
            title
            slug
          }
        }
      }
    }
  }
`
